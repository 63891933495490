@import "./mixins.scss";
@import "./variable.scss";

.ant-carousel .slick-dots li button {
    height: 9px !important;
    opacity: 0.8 !important;
    background-color: gray !important;
}

.ant-carousel .slick-dots li.slick-active button {
    height: 12px !important;
    background-color: black !important;
    opacity: 1 !important;
}

.hover-pointer{
    cursor: pointer;
}

div.ant-ribbon-placement-start {
    white-space: break-spaces !important;
    word-break: break-word !important;
    height: auto !important;
}

th.ant-table-column-has-sorters { 
    z-index: 0 !important;
}

.ant-menu-item .ant-badge{
    color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-item-selected .ant-badge{
    color: #FFD !important;
}

.ant-menu-item .ant-badge-count{
    right: -13px !important;
}

.hide-price-stepper .ant-input-number-handler-wrap {
    display: none;
}

.display-block { 
    display: block; 
}

td.ant-table-cell .status-dropdown {
    margin-left: -10px !important;
}

.image-delete-icon-container {
    position: relative;
    display: inline;
}

.btn-delete {
    position: absolute;
    left: 100px;
    margin-left: -10px;
    margin-top: 2px;
    cursor: pointer;
}

// count of callrail records badge color
.count-of-callrail-records sup.ant-badge-count {
    background-color: #1DA57A !important;
}

// set stickied columns to be highest z index and white background
td[style="position: sticky; left: 0px;"] {
    background-color: white !important;
    z-index: 9999999;
}
// set stickied columns to be highest z index and white background
td[style="position: sticky; right: 0px;"] {
    background-color: white !important;
    z-index: 9999999;
}
 
// Restricting the modal width if the width is larger than 1100px
.intake-form-modal {
    max-width: 73% !important;
}
@media only screen and (max-width: 1300px) {
    .intake-form-modal {
        max-width: 100% !important;
    }
}

// max width for chat message to be set to 60% of the screen width
.rce-mbox {
    max-width: 60% !important;
}

// if the chat message is a preset in drawer component
.preset-message .rce-mbox {
    max-width: 100% !important;
}

// message list margins
.rce-mlist {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

@media only screen and (max-width: 600px) {
  .rce-mlist {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

// scrollable chat functionality
.scrollable-container {
  height: 85vh !important;
  overflow: scroll !important;
}
.scrollable-container-message-list {
    height: 78vh !important;
    overflow: scroll !important;
    overflow-x: hidden;
    display: flex !important;
    flex-direction: column-reverse !important;
}
.scrollable-container-modal {
    height: 60vh !important;
    min-width: 400px !important;
    overflow: scroll !important;
    overflow-x: hidden;
    display: flex !important;
    flex-direction: column-reverse !important;
}

@media only screen and (max-height: 795px) {
    .scrollable-container-modal {
        height: 40vh !important;
    }
}

@media only screen and (max-width: 486px) {
    .scrollable-container-modal {
        min-width: 260px !important;
    }
}


::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
::placeholder { /* Recent browsers */
    text-transform: none;
}

.uppercase-transform {
    text-transform: uppercase;
}

.ant-input-affix-wrapper.uppercase-transform input {
    text-transform: uppercase;
}

// long text lines in select
.ant-select-item-option-content {
    overflow: auto !important;
    white-space: break-spaces !important;
    word-break: break-word !important;
}
.ant-select-show-arrow.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
    height: auto !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    overflow: auto !important;
    white-space: break-spaces !important;
    word-break: break-word !important;
}

.logo {
    float: left;
    height: 50px;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
    vertical-align: middle;
    background: url("https://i.imgur.com/MQGUWRX.png") no-repeat center center, white;
    background-size: 50px;
}

.img-hover-pointer:hover{
    cursor: pointer;
}

.top-margin {
    margin-top: 15px
}

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
    margin-right: 15px;
  }

// editable table
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

// Get arrows for carousel
.anticon.anticon-left.slick-arrow.slick-prev {
    display: block;
    color: black;
    background: transparent;
    font-size: 35px;
    left: -48px;
    z-index: 9999999999;
}
.anticon.anticon-right.slick-arrow.slick-next {
    display: block;
    color: black;
    background: transparent;
    font-size: 35px;
    right: -40px;
    z-index: 9999999999;
}
// End Get arrows for carousel

.ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    text-align: center;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color .3s;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: #000000d9;
    font-size: 16px;
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
    color: #00000073;
    font-size: 14px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: var(--ant-primary-5);
    font-size: 48px;
}
.selected-image {
    object-fit: contain;
    display: block
}

#root {
    height: 100%;
}

html {
    font-size: 62.5%;
    font-family: "Roboto", sans-serif;
}

h1,
p,
span,
label {
    margin: 0;
    font-family: "Roboto", sans-serif;
}

body {
    margin: 0;
    font-weight: 400;
    font-size: 1.6rem;
    font-family: "Roboto", sans-serif;
    background-color: rgba(246, 246, 247, 1);
}

.feature-container {
    max-width: 160rem;
}

.pro-menu-card {
    > .ant-card-head {
        padding: 0 2.4rem 0 0;
        .ant-card-head-title,
        .ant-card-extra {
            padding: 0;
            .ant-menu {
                border: 0;
                margin-bottom: 0.1rem;
                .ant-menu-item {
                    padding: 1rem 1.6rem;
                    margin-right: 1.6rem;
                    &:after {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

.w-full {
    width: 100% !important;
}

.h-full {
    height: 100% !important;
}

@mixin text-base {
    &.border {
        border-bottom: 1px solid;
    }
    &.italic {
        font-style: italic;
    }
    &.light {
        color: #78909c;
    }
    &.danger {
        color: #ff4d4f;
    }
    &.success {
        color: #87d068;
    }
    &.semi-bold {
        font-weight: 500;
    }
    &.bold {
        font-weight: 700;
    }
    &.normal {
        font-weight: 400;
    }
}

a,
.text-link {
    color: #202323;
    cursor: pointer;
    &:hover {
        color: $PRIMARY_COLOR !important;
    }
    &.no-border:not(:hover) {
        border: none;
    }
    &.reverse {
        color: $PRIMARY_COLOR;
    }
    @include text-base;
}

.text-small {
    line-height: 1.2;
    font-size: 1.2rem;
    @include text-base;
}

.text-xl {
    line-height: 1.6;
    font-size: 1.4rem;
    @include text-base;
}

.text-2xl {
    line-height: 1.6;
    font-size: 1.6rem;
    @include text-base;
}

.text-3xl {
    line-height: 1.6;
    font-size: 1.8rem;
    @include text-base;
}

.text-4xl {
    line-height: 1.6;
    font-size: 2.5rem;
    @include text-base;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.cursor-pointer {
    cursor: pointer;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.flex {
    display: flex;
}

.block {
    display: block;
}

.inline {
    display: inline;
}

.hidden {
    display: none;
}

.table {
    display: table;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.ant-form-item {
    margin-bottom: 1.6rem;
    &.no-margin {
        margin-bottom: 0;
    }
}

.ant-card.no-padding {
    > .ant-card-body {
        padding: 0;
    }
}

.ant-modal.no-padding {
    .ant-modal-body {
        padding: 0;
    }
}

.pro-card-table {
    .ant-card-body {
        padding: 0;
        .ant-card-loading-content {
            padding: 1.6rem 2.4rem;
        }
    }
}

.ant-table {
    overflow-x: auto;
}

.ant-table-thead > tr > th {
    border-top: 1px solid rgb(225, 227, 229);
    border-bottom: 1px solid rgb(225, 227, 229);
}

.ant-table-pagination.ant-pagination {
    margin: 1.6rem !important;
}

.ant-card {
    .ant-card-head {
        margin-bottom: 0;
    }
    .ant-card-body {
        .ant-menu-horizontal {
            border-bottom: 0;
        }
    }
    &.ant-card-bordered {
        border-color: rgb(225, 227, 229);
    }
    .ant-card-loading-content {
        padding: 1.6rem 2.4rem;
    }
    .ant-table-thead > tr > th {
        border-top: 0;
    }
    .ant-table-thead > tr > th:not(.ant-table-selection-column),
    .ant-table-tbody > tr > td:not(.ant-table-selection-column) {
        &:first-child {
            padding-left: 2.4rem;
        }
        &:last-child {
            padding-right: 2.4rem;
        }
    }
}

.ant-page-header {
    padding-top: 0 !important;
    padding-left: 0 !important;
    margin-top: -1.2rem !important;
    .ant-page-header-heading {
        align-items: center;
    }
}

@include list-loop(".pt-", "padding-top");
@include list-loop(".mt-", "margin-top");
@include list-loop(".pb-", "padding-bottom");
@include list-loop(".mb-", "margin-bottom");

.app-layout {
    min-height: 100%;
    .ant-layout-header {
        width: 100%;
        height: auto;
        line-height: 1.6;
        padding: 1rem 1.6rem;
        background-color: #ffffff;
        border-bottom: 1px solid rgb(225, 227, 229);
        .header-menu {
            cursor: pointer;
            min-width: 3.6rem;
            border-radius: 3px;
            padding: 0.8rem 1.2rem;
            transition: background-color 0.1s;
            &:hover {
                transition: background-color 0.1s;
                background-color: rgb(246, 246, 247);
            }
        }
    }
    .ant-layout {
        background: rgba(246, 246, 247, 1);
        .ant-layout-content {
            padding: 3rem;
        }
    }
}

.login-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: "white";
    .image-card {
        height: 150px;
        width: 100%;
        vertical-align: middle;
        background: url("https://i.imgur.com/MQGUWRX.png") no-repeat center center;
        background-size: 150px;
    }
    .ant-card {
        width: 36rem;
    }
}

.auction-item-horizontal-table {
    table {
        tr th {
            text-align: left;
            &.text-center {
                text-align: center;
            }
        }
        tr {
            td {
                white-space: normal !important;
                &:not(:last-child) {
                    border-right: 1px solid #f0f0f0 !important;
                }
            }
        }
        th {
            border-bottom: 1px solid #f0f0f0 !important;
            &:not(:last-child) {
                border-right: 1px solid #f0f0f0 !important;
            }
        }
        .component-col {
            border: 0 !important;
            padding: 0 !important;
            th {
                border-left: 0 !important;
            }
            td {
                border-right: 0 !important;
            }
        }
        .ant-image {
            width: 100%;
            .ant-image-img {
                height: 10rem;
                object-fit: contain;
            }
        }
        .component-img {
            height: 17rem !important;
        }
        .auction-img {
            height: 11rem !important;
        }
        .ant-btn {
            display: block;
            margin: 0 auto;
        }
    }
}

.horizontal-table-wrapper {
    overflow-x: auto;
    table {
        width: auto !important;
        margin: 1.6rem 1.6rem 0 1.6rem;
        tbody {
            vertical-align: top !important;
        }
        tr th {
            white-space: pre;
            &.text-center {
                text-align: center;
            }
        }
        tr {
            td {
                width: 18rem;
                white-space: pre;
                padding: 0.4rem 1.2rem;
                border: 1px solid #f0f0f0;
                &:not(:last-child) {
                    border-right: 0;
                }
            }
        }
        th {
            padding: 0.4rem 1.2rem;
            border: 1px solid #f0f0f0;
            border-bottom: 0;
            &:not(:last-child) {
                border-right: 0;
            }
        }
    }
    .pagination {
        padding: 1.6rem;
    }
}

.auction-item-list {
    .ant-card-head-wrapper {
        align-items: flex-start;
        .ant-col,
        .ant-form-item-label > label {
            font-weight: 400;
        }
    }
    .table-header-right-col {
        flex: 1;
    }
}

.auction-item-edit {
    .components-card {
        padding: 2rem;
        margin: 2rem 0;
        border-radius: 6px;
        position: relative;
        border: 2px solid $PRIMARY_COLOR;
        background-color: rgb(251, 251, 253);
        .delete-trigger {
            top: 1.2rem;
            right: 2.2rem;
            font-size: 2rem;
            position: absolute;
        }
        .collapse-trigger {
            top: 1.2rem;
            right: 5.2rem;
            font-size: 2rem;
            cursor: pointer;
            position: absolute;
        }
        .attribute-col {
            &.hidden {
                display: none;
            }
        }
    }
    .images-col {
        top: 0;
        right: 0rem;
        width: 38rem;
        z-index: 999;
        position: absolute;
        .selected-image {
            height: 25rem;
            object-fit: contain;
            margin-bottom: 2.4rem;
        }
        .ant-space {
            display: flex;
            margin-bottom: 1.6rem;
            .image-item {
                width: 8rem;
                height: 8rem;
                cursor: pointer;
                object-fit: contain;
                &.selected {
                    border: 2px solid $PRIMARY_COLOR;
                }
            }
        }
        .user-upload {
            display: table;
        }
    }
    .is-approved-checkbox {
        .ant-checkbox-inner {
            width: 2rem;
            height: 2rem;
        }
    }
}

.auction-item-add-attr-modal {
    .picklist-option-item {
        width: 100%;
        .ant-space-item:first-child {
            flex: 1;
        }
    }
}

.attr-value-dropdown {
    .ant-divider {
        margin: 1.2rem 0;
    }
    .add-attr-value {
        display: flex;
        align-items: center;
        padding: 0 1.2rem 1.2rem;
        input {
            flex: 1;
            margin-right: 1.2rem;
        }
    }
}

.customer-assignment-modal {
    .ant-card {
        .ant-table {
            .ant-table-expanded-row {
                > td {
                    padding: 1.6rem !important;
                    .ant-table {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.customer-collection-card {
    .ant-table {
        .ant-table-expanded-row {
            > td {
                padding: 1.6rem !important;
                .ant-table {
                    margin: 0 !important;
                }
            }
        }
    }
    .comparable-row-tables {
        .customer-collection-simple-table {
            background: #ebe8e8;
        }
    }
    .comparable-row-item {
        > .ant-table-cell {
            background: #ebe8e8;
        }
    }
    .customer-collection-simple-table {
        width: auto !important;
        margin-bottom: 1.6rem;
        tbody {
            vertical-align: top !important;
        }
        .item-name-cell,
        .notes-cell {
            width: 30rem !important;
        }
        .action-cell {
            padding: 0;
            .ant-btn {
                border-radius: 0;
            }
        }
        tr {
            td {
                width: 18rem;
                padding: 0.4rem 1.2rem;
                border: 1px solid #f0f0f0;
                &:not(:last-child) {
                    border-right: 0;
                }
            }
        }
        th {
            padding: 0.4rem 1.2rem;
            border: 1px solid #f0f0f0;
            border-bottom: 0;
            &:not(:last-child) {
                border-right: 0;
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 4240px) {
    .app-layout {
        .ant-layout-sider {
            overflow: auto !important;
            position: sticky !important;
            height: 100vh !important;
            left: 0 !important;
            top: 0 !important;
            bottom: 0 !important;
            flex: 20rem !important;
            width: 22rem !important;
            min-width: 20rem !important;
            max-width: 22rem !important;
            .ant-menu-item {
                margin-right: 1.6rem !important;
            }
            &.ant-layout-sider-collapsed {
                display: none;
            }
        }
        .ant-layout-header {
            z-index: 1 !important;
            overflow: auto !important;
            position: sticky !important;
            left: 0 !important;
            right: 0 !important;
            top: 0 !important;
        }
        .jewelry-filter-component {
            display: block;
        }
        .table-header-right-col {
            .ant-form {
                > .ant-row {
                    display: block;
                    > .ant-col {
                        &:first-child {
                            margin-bottom: 1.2rem;
                        }
                        align-items: flex-start !important;
                    }
                }
            }
        }
        .auction-management {
            .ant-card-head-wrapper {
                display: block;
                .ant-card-head-title {
                    padding-bottom: 0;
                }
                .ant-card-extra {
                    float: none;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    > div {
                        margin: 0 !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .app-layout {
        .ant-layout-sider {
            flex: 16rem !important;
            width: 16rem !important;
            min-width: 16rem !important;
            max-width: 16rem !important;
            .ant-menu-item {
                padding: 0 1rem !important;
                .ant-menu-title-content {
                    margin: 3;
                }
            }
            &.ant-layout-sider-collapsed {
                display: none;
            }
        }
        .ant-layout {
            .ant-layout-content {
                padding: 1rem 1.6rem;
            }
            .ant-card {
                .ant-card-head {
                    padding: 0 1.6rem;
                }
            }
        }
    }
    .ant-form {
        .ant-row {
            flex-wrap: wrap;
            .ant-col {
                max-width: 100%;
                flex: 100% !important;
            }
        }
    }
    .ant-modal {
        top: 2rem !important;
        .ant-modal-content {
            .ant-modal-header {
                padding: 1rem 1.6rem;
            }
            .ant-modal-close {
                top: -0.4rem;
            }
            .ant-modal-body {
                padding: 1rem 1.6rem;
            }
        }
    }
    .auction-item-edit {
        .images-col {
            width: auto;
            position: unset;
        }
    }
    .jewelry-filter-component {
        flex-wrap: wrap;
    }
    .jewelry-range-filter {
        .ant-row {
            flex-flow: row wrap;
            .ant-col {
                flex: 50% !important;
                &:last-child {
                    text-align: right;
                }
            }
        }
    }
    .auction-management,
    .customer-collection-card {
        .ant-card-head-wrapper {
            display: block;
            .ant-card-head-title {
                display: block;
                padding-bottom: 0;
            }
            .ant-card-extra {
                float: none;
                padding: 0;
            }
            .ant-space,
            .ant-row {
                display: block;
            }
            .ant-space-item {
                margin-bottom: 1.2rem;
            }
            .ant-input,
            .ant-picker,
            .ant-select {
                width: 100% !important;
            }
        }
    }
    .customer-collection-card {
        .ant-row {
            > .ant-col {
                &:last-child {
                    .ant-space {
                        display: flex;
                        margin-top: 1.2rem;
                        align-items: flex-start;
                        flex-direction: column-reverse;
                        .ant-space-item {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .table-header-right-col {
        display: block;
        margin-top: 1.2rem;
        .ant-row {
            display: block;
            .ant-col {
                display: block;
                .ant-space {
                    width: 100%;
                    display: block;
                    margin-bottom: 0 !important;
                    .ant-space-item {
                        margin-bottom: 1.2rem;
                    }
                }
                .ant-input,
                .ant-picker,
                .ant-select {
                    width: 100% !important;
                }
            }
        }
    }
}
